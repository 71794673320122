import React from 'react'
import styled from 'styled-components'

import { color } from '../shared/styles'

const Wrapper = styled.div`
  width: 100%;
  max-height: 500px;
  height: 100%;
  background: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Slide = ({ image, name }) => {
  return (
    <Wrapper>
      <img src={image} alt={name} />
    </Wrapper>
  )
}

export default Slide
