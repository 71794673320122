import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import Seo from '../components/seo'
import CallToAction from '../components/CallToAction'
import Container from '../components/shared/Container'
import Slider from '../components/ProductSlider'
import Button from '../components/Button/Button'

import { color, fontSize, lineHeight } from '../components/shared/styles'
import DownloadIcon from '../images/download-icon.png'

export const data = graphql`
  query($route: String!)
  {
    allSanityProduct(filter: {route: {current: {eq: $route}}}) {
      edges {
        node {
          cutsheetSectionInfo
          quoteSectionMsg
          heroImage {
            asset {
              url
            }
          }
          bgImage {
            asset {
              url
            }
          }
          cutsheet {
            asset {
              url
            }
          }
          description
          pageDescription
          ogimage {
            asset {
              url
            }
          }
          displayHome
          footNotes
          id
          CTAMessage
          images {
            asset {
              url
            }
          }
          name
          performance
          route {
            current
          }
          subTitle
        }
      }
    }
    allSanityCta {
      nodes {
        id
        name
        text
        to
        buttonText
        bgImgUrl {
          asset {
            url
          }
        }
      }
    }
    allSanityProductsPage {
        nodes {
          title
          topSectionImage {
            asset {
              url
            }
          }
        }
      }
  }
`

const TopSection = styled.section`
  height: auto;
  background-color: #111111;
  
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    padding: 150px 0 100px 0;
    align-items: center;

    @media screen and (max-width: 1160px) {
      grid-template-columns: 1fr;
      grid-gap: 50px;
      justify-content: center;
    }
  
    img {
      width: 100%;
      max-height: 50vh;
      object-fit: contain;
      margin-bottom: 0;
    }
  
    .product__title {
      color: ${color.white};
  
      h1 {
        text-transform: capitalize;
        font-weight: bolder;
        font-size: ${fontSize.lg};
        line-height: ${lineHeight.lg};
        margin-bottom: 0;
      }
  
      h2 {
        font-size: 25px;
        line-height: 30px;
        font-weight: normal;
        margin-bottom: 0;
        max-width: 450px;
      }

      @media screen and (max-width: 1160px) {
        padding: 0 30px;

        h1, h2 {
          text-align: center;
          max-width: none;
        }
      }
    }
  }
`

const QuoteSection = styled.section`
  height: auto;
`

const ProductInfoSection = styled.section`
  background-color: ${color.white};
  padding: 100px;

  @media screen and (max-width: 1160px) {
    padding: 50px;
  }
  
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;

    @media screen and (max-width: 1160px) {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }

    .product {
      &__carousel {

      }

      &__info {
        color: ${color.black};
        font-size: 18px;
        line-height: 29.11px;

        [class^="Button"] {
          margin-top: 50px;

          @media screen and (max-width: 1160px) {
            text-align: center;
          }
        };
        
      }
    }
  }
`

const CutsheetSection = styled.section`
  padding: 100px 0;
  background-color: ${color.black};
  
  @media screen and (max-width: 1160px) {
    padding: 50px;
  }

  > div {
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;

    @media screen and (max-width: 1160px) {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }

    .cutsheet {
      &__info {
        color: ${color.white};
        font-weight: 500;
        font-size: 36px;
        line-height: 58.21px;
        margin: 0;

        @media screen and (max-width: 660px) {
          font-size: 28px;
          line-height: 32px;
        }
      }

      &__footnote {
        margin-bottom: 0;

        small {
          color: #B6B6B6;
          font-size: 14px;
          font-weight: normal;
          line-height: 22.64px;
        }
      }
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: ${color.green};
      font-weight: 500;
      font-size: ${fontSize.sm};
    }
  }
`

const CallToActionSection = styled.section`
  height: auto;
`

const ProductTemplate = ({ data }) => {
  const product = data?.allSanityProduct?.edges[0]?.node

  const ctas = data?.allSanityCta?.nodes?.map(
    ({ name, bgImgUrl }) => ({ name, bgImgUrl: bgImgUrl.asset.url })
  )

  const ctaBg = ctas.find(({ name }) => name === 'home_1').bgImgUrl

  const quoteSectionBg = data?.allSanityProductsPage?.nodes[0]?.topSectionImage?.asset?.url
  
  return (
    <Layout>
      <Seo
        title={product?.name || ''}
        description={product?.pageDescription || ''}
        image={product?.ogimage?.asset?.url || ''}
      />
      <TopSection>
        <Container>
          <img src={product?.heroImage?.asset?.url} alt={product?.name || 'Imagen del producto'} />
          <div className="product__title">
            <h1>{product?.name}</h1>
            <h2>{product?.subTitle}</h2>
          </div>
        </Container>
      </TopSection>
      <QuoteSection>
        <CallToAction
          text={product?.quoteSectionMsg || ''}
          bgImgUrl={quoteSectionBg}
        />
      </QuoteSection>
      <ProductInfoSection>
        <Container>
          <div className="product__carousel">
            <Slider
              slides={product?.images}
              infiniteLoop
              autoPlay
              showArrows
              interval={5000}
              showStatus={false}
              showThumbs={false}
              productName={product?.name}
            />
          </div>
          <div className="product__info">
            {product?.description?.split('\n').map((paragraph, idx) => <p key={`desc-p-${idx}`}>{paragraph}</p>)}
            <Button to="/contacto" variant="black">CONOCER MÁS</Button>
          </div>
        </Container>
      </ProductInfoSection>
      <CutsheetSection>
        <Container>
          <p className="cutsheet__info">{product?.cutsheetSectionInfo}</p>
          <a href={product?.cutsheet?.asset?.url || ''} download>
            <img src={DownloadIcon} alt="Descargar Cutsheet" width={100} />
            Descargar Cutsheet
          </a>
          {product?.footNotes && <p className="cutsheet__footnote"><small>*{product?.footNotes}</small></p>}
        </Container>
      </CutsheetSection>
      <CallToActionSection>
        <CallToAction
          text={product?.CTAMessage || `¿Quieres saber más sobre la ${product?.name}?`}
          to="/contacto"
          buttonText="CONTÁCTANOS"
          bgImgUrl={ctaBg}
          overlayColor='linear-gradient(0deg, rgba(34, 34, 34, 0.53), rgba(34, 34, 34, 0.63))'
          isBgFixed
        />
      </CallToActionSection>
    </Layout>
  )
}

export default ProductTemplate
